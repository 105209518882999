import { ImSubscriptionActionBlocker } from "../subscription/ImSubscriptionActionBlocker";
import ExportButton from "../../common/ExportButton";

interface Props {
    isExportLoad: boolean;
    handleExport: () => void;
    disabled?: boolean;
}

export default function ImExportButton({
    isExportLoad,
    handleExport,
    disabled = false,
}: Props) {
    return (
        <ImSubscriptionActionBlocker>
            {(blocked) => (
                <ExportButton
                    isExportLoad={isExportLoad}
                    handleExport={handleExport}
                    disabled={disabled || blocked}
                />
            )}
        </ImSubscriptionActionBlocker>
    );
}
